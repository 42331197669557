import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { ExtraPaths, Paths } from "@/res/data/Paths";
import { pageTitle } from "@/res/helpers/PageHelper";
import { IS_SERVER } from "@/res/helpers/ServiceHelper";

const Root = (props) => {
    const [countries, setCountries] = useState([]);
    const router = useRouter();

    useEffect(() => {
        if (IS_SERVER) return;
        router.replace("/no")

        let c = {};
        [...Paths, ...ExtraPaths].forEach(path => {
            let p = path.params;
            c[p?.country] = {
                country: p?.country,
                label: p?.country_details?.name,
                flag: p?.country_details?.flag?.round,
            };
        });
        let countryArray = [];
        let specialArray = [];
        Object.keys(c).map(item => {
            if (item?.length === 2) {
                countryArray.push(c[item]);
            } else {
                specialArray.push(c[item]);
            }
        });
        countryArray = countryArray.sort((a, b) => {
            if (a.label < b.label) { return -1; }
            if (a.label > b.label) { return 1; }
            return 0;
        });
        setCountries([...specialArray, ...countryArray]);
    }, []);

    return (
        <>
            { pageTitle("Select country") }

            <main>
                <section className="cover section-center">
                    <div className="container">
                        <div className="row justify-content-center center">
                            <div className="col-12 col-lg-10">
                                <div className="column-group">
                                    {Object.keys(countries ?? {}).map((c, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className="btn-country"
                                                onClick={() => {
                                                    router.push(window.location.pathname + countries[c].country)
                                                }}
                                            >
                                                <div>
                                                    <img src={countries[c].flag} alt=""/>
                                                    <div className="img-border"/>
                                                </div>
                                                <div>
                                                    {countries[c].label}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
};

export default Root;
